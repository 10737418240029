import { Injectable } from "@angular/core";

export interface NavigationItem {
  id: string;
  title: string;
  type: "item" | "collapse" | "group";
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
    notification: boolean;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

let oauthUser = JSON.parse(localStorage.getItem("auth-user"));

var superAdminNavigationItems = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      {
        id: "dashboard",
        title: "dashboard",
        type: "item",
        url: "/dashboard",
        icon: "feather icon-home",
        classes: "nav-item",
      },
      {
        id: "companies",
        title: "companies",
        type: "item",
        url: "/companies",
        icon: "feather icon-globe",
        classes: "nav-item",
      },

      /*       {
              id: "search",
              title: "companies",
              type: "item",
              url: "/companies/list",
              icon: "feather icon-globe",
              classes: "nav-item",
            }, */
    ],
  },
];

var companyAdminNavigationItems = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      {
        id: "dashboard",
        title: "dashboard",
        type: "item",
        url: "/dashboard",
        icon: "feather icon-home",
        classes: "nav-item",
      },
      /*     {
            id: "search",
            title: "search",
            type: "item",
            url: "/search/all",
            classes: "nav-item",
            icon: "feather icon-search",
          }, */
      {
        id: "entities",
        title: "entities",
        type: "item",
        url: "/entities",
        classes: "nav-item",
        icon: "feather icon-bookmark",
      },
      {
        id: "human-resources",
        title: "human_resources",
        type: "item",
        url: "/human-resources",
        classes: "nav-item",
        icon: "feather icon-users",
      },

      /*{
        id: "assessments",
        title: "assessments",
        type: "item",
        url: "/assessments",
        classes: "nav-item",
        icon: "feather icon-file-text",
      },*/
      {
        id: "cooptation",
        title: "cooptation",
        type: "item",
        url: "/cooptations",
        classes: "nav-item",
        icon: "feather icon-star",
      },
      {
        id: "requirements",
        title: "opportunities",
        type: "item",
        url: "/requirements",
        icon: "feather icon-heart",
        classes: "nav-item",
      },

      /* {
        id: "Ambassador program",
        title: "Ambassador program",
        type: "collapse",
        icon: "feather icon-award",
        children: [
          {
            id: "Ambassador program",
            title: "programs",
            type: "item",
            url: "/ambassador-program",
            classes: "nav-item",
            icon: "feather icon-layers",
          },
          {
            id: "pending_programs",
            title: "pending_programs",
            type: "item",
            url: "/ambassador-program/pending",
            classes: "nav-item",
            icon: "feather icon-bell",
          },
          {
            id: "value point list",
            title: "value_point",
            type: "item",
            url: "/ambassador-program/value-point-list",
            classes: "nav-item",
            icon: "feather icon-star",
          }
        ]
      },
       {
        id: "annual-assessments",
        title: "Annual Assessment",
        type: "collapse",
        icon: "feather icon-clipboard",
        children: [
          {
            id: "annual-assessments-list",
            title: "add-annual-assessments",
            type: "item",
            url: "/annual-assessment-admin/add-annual-assessment-template",
            classes: "nav-item",
            icon: "feather icon-layers",
          },
          {
            id: "annual-assessments",
            title: "admin_annual_assessment",
            type: "item",
            url: "/annual-assessment-admin",
            classes: "nav-item",
            icon: "feather icon-edit",
          }

        ]
      },  */


      /*        {
               id: "missions",
               title: "missions",
               type: "item",
               url: "/missions",
               classes: "nav-item",
               icon: "feather icon-briefcase",
             }, */
      {
        id: "send-news",
        title: "News",
        type: "item",
        url: "/send-news",
        icon: "feather icon-mail",
        classes: "nav-item",
      },
      {
        id: "skills",
        title: "skills",
        type: "item",
        url: "/skills",
        classes: "nav-item",
        icon: "feather icon-layers",
      },
      {
        id: "matching-assistance",
        title: "matching-assistance",
        type: "item",
        url: "/matching-assistance",
        classes: "nav-item",
        icon: "feather icon-cpu",
      }
    ],
  },
];
var businessManagerNavigationItems = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      {
        id: "dashboard",
        title: "dashboard",
        type: "item",
        url: "/dashboard",
        icon: "feather icon-home",
        classes: "nav-item",
      },

      /*   {
          id: "search",
          title: "search",
          type: "item",
          url: "/search/all",
          classes: "nav-item",
          icon: "feather icon-search",
        },  */
      /*        {
              id: "entities",
              title: "entities",
              type: "item",
              url: "/entities",
              classes: "nav-item",
              icon: "feather icon-bookmark",
            }, */
      {
        id: "human-resources",
        title: "human_resources",
        type: "item",
        url: "/human-resources",
        classes: "nav-item",
        icon: "feather icon-users",
      },
      {
        id: "cooptation",
        title: "cooptation",
        type: "item",
        url: "/cooptations",
        classes: "nav-item",
        icon: "feather icon-star",
      },
      /*         {
              id: "annual-assessments",
              title: "Annual Assessment",
              type: "collapse",
              icon: "feather icon-clipboard",
              children: [
                {
                  id: "annual-assessments-list",
                  title: "add-annual-assessments",
                  type: "item",
                  url: "/annual-assessment-admin/add-annual-assessment-template",
                  classes: "nav-item",
                  icon: "feather icon-layers",
                },
                {
                  id: "annual-assessments",
                  title: "admin_annual_assessment",
                  type: "item",
                  url: "/annual-assessment-admin",
                  classes: "nav-item",
                  icon: "feather icon-edit",
                }
              ]
            },
            {
              id: "Ambassador program",
              title: "Ambassador program",
              type: "collapse",
              icon: "feather icon-award",
              children: [
                {
                  id: "Ambassador program",
                  title: "programs",
                  type: "item",
                  url: "/ambassador-program",
                  classes: "nav-item",
                  icon: "feather icon-layers",
                },
                {
                  id: "pending_programs",
                  title: "pending_programs",
                  type: "item",
                  url: "/ambassador-program/pending",
                  classes: "nav-item",
                  icon: "feather icon-bell",
                },
                {
                  id: "value point list",
                  title: "value_point",
                  type: "item",
                  url: "/ambassador-program/value-point-list",
                  classes: "nav-item",
                  icon: "feather icon-star",
                }
              ]
            },  */
      /* {
         id: "assessments",
         title: "assessments",
         type: "item",
         url: "/assessments",
         classes: "nav-item",
         icon: "feather icon-file-text",
       },*/
      /*       {
              id: "missions",
              title: "missions",
              type: "item",
              url: "/missions",
              classes: "nav-item",
              icon: "feather icon-briefcase",
            }, */
      {
        id: "requirements",
        title: "opportunities",
        type: "item",
        url: "/requirements",
        icon: "feather icon-heart",
        classes: "nav-item",
      },

      {
        id: "send-news",
        title: "News",
        type: "item",
        url: "/send-news",
        icon: "feather icon-mail",
        classes: "nav-item",
      },
      {
        id: "skills",
        title: "skills",
        type: "item",
        url: "/skills",
        classes: "nav-item",
        icon: "feather icon-layers",
      },
      {
        id: "matching-assistance",
        title: "matching-assistance",
        type: "item",
        url: "/matching-assistance",
        classes: "nav-item",
        icon: "feather icon-cpu",
      }

    ],
  },
];
var consultantNavigationItems = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      /* {
         id: "dashboard",
         title: "dashboard",
         type: "item",
         url: "/dashboard",
         icon: "feather icon-home",
         classes: "nav-item",
       },*/
      {
        id: "technical-document",
        title: "technicalDocument",
        type: "item",
        url: "/technical-document/consultant/" + oauthUser?.sub,
        classes: "nav-item",
        icon: "feather icon-clipboard",
      },
      /* {
         id: "assessments",
         title: "assessments",
         type: "item",
         url: "/assessments/details/" + oauthUser?.sub,
         classes: "nav-item",
         icon: "feather icon-file-text",
       },*/
      /*        {
               id: "mission",
               title: "missions",
               type: "item",
               url: "/mission",
               classes: "nav-item",
               icon: "feather icon-briefcase",
             }, */
      {
        id: "cooptation",
        title: "cooptation",
        type: "item",
        url: "/cooptations",
        classes: "nav-item",
        icon: "feather icon-star",
      },
      {
        id: "requirements",
        title: "opportunities",
        type: "item",
        url: "/requirements",
        icon: "feather icon-heart",
        classes: "nav-item",
      },
      /*
              {
              id: "Ambassador program",
              title: "Ambassador program",
              type: "item",
              url: "/ambassador-program",
              icon: "feather icon-award",
              classes: "nav-item",
            },

            {
              id: "annual-assessments",
              title: "Annual Assessment",
              type: "collapse",
              icon: "feather icon-clipboard",
              children: [
                {
                  id: "annual-assessments-consultant",
                  title: "annual_assessment",
                  type: "item",
                  url: "/annual-assessments",
                  classes: "nav-item",
                  icon: "feather icon-edit",
                },
                {
                  id: "annual-assessments-list",
                  title: "historique-annual-assessments",
                  type: "item",
                  url: "/annual-assessments-list",
                  classes: "nav-item",
                  icon: "feather icon-layers",
                },
              ]
            }  */

    ],
  },
];
var recruiterNavigationItems = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      {
        id: "dashboard",
        title: "dashboard",
        type: "item",
        url: "/missions",
        icon: "feather icon-home",
        classes: "nav-item",
      },
      {
        id: "skills",
        title: "skills",
        type: "item",
        url: "/skills",
        classes: "nav-item",
        icon: "feather icon-layers",
      }

      /*       {
              id: "assessments",
              title: "assessments",
              type: "item",
              url: "/assessments",
              classes: "nav-item",
              icon: "feather icon-file-text",
            }, */
    ],
  },
];
var candidateNavigationItems = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      {
        id: "dashboard",
        title: "dashboard",
        type: "item",
        url: "/missions",
        icon: "feather icon-home",
        classes: "nav-item",
      },

      /*       {
              id: "assessments",
              title: "assessments",
              type: "item",
              url: "/assessments",
              classes: "nav-item",
              icon: "feather icon-file-text",
            }, */
    ],
  },
];

var humanRessourceNavigationItems = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      {
        id: "dashboard",
        title: "dashboard",
        type: "item",
        url: "/dashboard",
        icon: "feather icon-home",
        classes: "nav-item",
      },
      {

        id: "human-resources",
        title: "human_resources",
        type: "item",
        url: "/human-resources",
        classes: "nav-item",
        icon: "feather icon-users",
      },
      /*        {
              id: "entities",
              title: "entities",
              type: "item",
              url: "/entities",
              classes: "nav-item",
              icon: "feather icon-bookmark",
            },  */

      {
        id: "cooptation",
        title: "cooptation",
        type: "item",
        url: "/cooptations",
        classes: "nav-item",
        icon: "feather icon-star",
      },
      {
        id: "requirements",
        title: "opportunities",
        type: "item",
        url: "/requirements",
        icon: "feather icon-heart",
        classes: "nav-item",
      },
      /*         {
                id: "Ambassador program",
                title: "Ambassador program",
                type: "collapse",
                icon: "feather icon-award",
                children: [
                  {
                    id: "Ambassador program",
                    title: "programs",
                    type: "item",
                    url: "/ambassador-program",
                    classes: "nav-item",
                    icon: "feather icon-layers",
                  },
                  {
                    id: "pending_programs",
                    title: "pending_programs",
                    type: "item",
                    url: "/ambassador-program/pending",
                    classes: "nav-item",
                    icon: "feather icon-bell",
                  },
                  {
                    id: "value point list",
                    title: "value_point",
                    type: "item",
                    url: "/ambassador-program/value-point-list",
                    classes: "nav-item",
                    icon: "feather icon-star",
                  }
                ]
              }, */
      {
        id: "skills",
        title: "skills",
        type: "item",
        url: "/skills",
        classes: "nav-item",
        icon: "feather icon-layers",
      },
      {
        id: "send-news",
        title: "News",
        type: "item",
        url: "/send-news",
        icon: "feather icon-mail",
        classes: "nav-item",
      },
      /*       {
              id: "annual-assessments",
              title: "Annual Assessment",
              type: "collapse",
              icon: "feather icon-clipboard",
              children: [
                {
                  id: "annual-assessments-list",
                  title: "add-annual-assessments",
                  type: "item",
                  url: "/annual-assessment-admin/add-annual-assessment-template",
                  classes: "nav-item",
                  icon: "feather icon-layers",
                },
                {
                  id: "annual-assessments",
                  title: "admin_annual_assessment",
                  type: "item",
                  url: "/annual-assessment-admin",
                  classes: "nav-item",
                  icon: "feather icon-edit",
                }

              ]
            },  */

    ],
  },
];

var visitorNavigationItems = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [

      {
        id: "cooptation",
        title: "cooptation",
        type: "item",
        url: "/cooptations",
        classes: "nav-item",
        icon: "feather icon-star",
      }

    ],
  },
];
var clientContactNavigationItems = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      {
        id: "dashboard",
        title: "dashboard",
        type: "item",
        url: "/dashboard",
        icon: "feather icon-home",
        classes: "nav-item",
      },
      {
        id: "missions",
        title: "Missions",
        type: "item",
        url: "/client_contact/missions",
        classes: "nav-item",
        icon: "feather icon-briefcase",
      },
      {
        id: "consultants",
        title: "Consultants",
        type: "item",
        url: "/client_contact/consultants",
        classes: "nav-item",
        icon: "feather icon-users",
      },
      {
        id: "assessment",
        title: "Assessment",
        type: "item",
        url: "/client_contact/assessments",
        classes: "nav-item",
        icon: "feather icon-file",
      }


    ],
  },
];


var companyAdminNavigationItemsNotHarington = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      {
        id: "human-resources",
        title: "human_resources",
        type: "item",
        url: "/human-resources",
        classes: "nav-item",
        icon: "feather icon-users",
      },
      {
        id: "cooptation",
        title: "cooptation",
        type: "item",
        url: "/cooptations",
        classes: "nav-item",
        icon: "feather icon-star",
      }
    ],
  },
];


var businessManagerNavigationItemsNotHarington = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      {
        id: "human-resources",
        title: "human_resources",
        type: "item",
        url: "/human-resources",
        classes: "nav-item",
        icon: "feather icon-users",
      },
      {
        id: "cooptation",
        title: "cooptation",
        type: "item",
        url: "/cooptations",
        classes: "nav-item",
        icon: "feather icon-users",
      }
    ],
  },
];
var consultantNavigationItemsNotHarington = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      {
        id: "cooptation",
        title: "cooptation",
        type: "item",
        url: "/cooptations",
        classes: "nav-item",
        icon: "feather icon-star",
      }
    ],
  },
];
var recruiterNavigationItemsNotHarington = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      /* {
        id: "dashboard",
        title: "dashboard",
        type: "item",
        url: "/missions",
        icon: "feather icon-home",
        classes: "nav-item",
      },

      {
        id: "assessments",
        title: "assessments",
        type: "item",
        url: "/assessments",
        classes: "nav-item",
        icon: "feather icon-file-text",
      }, */
    ],
  },
];
var candidateNavigationItemsNotHarington = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      /* {
        id: "dashboard",
        title: "dashboard",
        type: "item",
        url: "/missions",
        icon: "feather icon-home",
        classes: "nav-item",
      },

      {
        id: "assessments",
        title: "assessments",
        type: "item",
        url: "/assessments",
        classes: "nav-item",
        icon: "feather icon-file-text",
      }, */
    ],
  },
];

var humanRessourceNavigationItemsNotHarington = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      {

        id: "human-resources",
        title: "human_resources",
        type: "item",
        url: "/human-resources",
        classes: "nav-item",
        icon: "feather icon-users",
      },
      {
        id: "cooptation",
        title: "cooptation",
        type: "item",
        url: "/cooptations",
        classes: "nav-item",
        icon: "feather icon-star",
      },
    ],
  },
];

var visitorNavigationItemsNotHarington = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [

      {
        id: "cooptation",
        title: "cooptation",
        type: "item",
        url: "/cooptations",
        classes: "nav-item",
        icon: "feather icon-star",
      }

    ],
  },
];

var clientContactNavigationItemsNotHarington = [
  {
    id: "navigation",
    title: "Navigation",
    type: "group",
    icon: "feather icon-monitor",
    children: [
      /* {
        id: "dashboard",
        title: "dashboard",
        type: "item",
        url: "/dashboard",
        icon: "feather icon-home",
        classes: "nav-item",
      },
      {
        id: "missions",
        title: "Missions",
        type: "item",
        url: "/mission-client",
        classes: "nav-item",
        icon: "feather icon-briefcase",
      } */

    ],
  },
];


@Injectable()
export class NavigationItem {
  public get() {
    let oauthUser = JSON.parse(localStorage.getItem("auth-user"));
    let user = JSON.parse(localStorage.getItem("user-data"));
    if (oauthUser && oauthUser.roles.indexOf("SUPER_ADMIN") !== -1) {
      return superAdminNavigationItems;
    } else if (oauthUser && oauthUser.roles.indexOf("COMPANY_ADMIN") !== -1) {
      if (user && user?.companyEntity?.company?.id == 2) {
        return companyAdminNavigationItems;
      } else {
        return companyAdminNavigationItemsNotHarington
      }
    } else if (
      oauthUser &&
      oauthUser.roles.indexOf("BUSINESS_MANAGER") !== -1
    ) {
      if (user && user?.companyEntity?.company?.id == 2) {
        return businessManagerNavigationItems;
      } else {
        return businessManagerNavigationItemsNotHarington
      }
    } else if (oauthUser && oauthUser.roles.indexOf("CONSULTANT") !== -1) {
      if (user && user?.companyEntity?.company?.id == 2) {
        return consultantNavigationItems;
      } else {
        return consultantNavigationItemsNotHarington
      }
    } else if (oauthUser && oauthUser.roles.indexOf("RECRUITER") !== -1) {
      if (user && user?.companyEntity?.company?.id == 2) {
        return recruiterNavigationItems;
      } else {
        recruiterNavigationItemsNotHarington
      }
    } else if (oauthUser && oauthUser.roles.indexOf("HUMAN_RESOURCE") !== -1) {
      if (user && user?.companyEntity?.company?.id == 2) {
        return humanRessourceNavigationItems;
      } else {
        return humanRessourceNavigationItemsNotHarington
      }
    } else if (oauthUser && oauthUser.roles.indexOf("VISITOR") !== -1) {
      if (user && user?.companyEntity?.company?.id == 2) {
        return visitorNavigationItems;
      } else {
        return visitorNavigationItemsNotHarington
      }
    } else if (oauthUser && oauthUser.roles.indexOf("CLIENT_CONTACT") !== -1) {
      return clientContactNavigationItems;
    }


  }
}
